/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from "axios";
import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";
import Chart from "chart.js";
import { store } from "@/app/store";
import { BASE_URL_MANAGER } from "@/config";
// Components
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";
require("/src/assets/css/style.css");
@Component({
  name: "administrator-dashboard",
  components: { AdminNavbar, NavBar },
})
export default class AdministratorDashboard extends VueWizard {
  public users = "";
  public agentsLength = 0;
  public selectKey = 2;
  public user: any;
  public accountData = {
    type: "line",
    data: {
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      datasets: [
        {
          label: "Cuentas registradas",
          data: [0, 1, 3, 5, 2, 2, 8, 12, 20, 13, 12, 10],
          backgroundColor: ["rgba(71, 183, 132, .5)"],
          borderColor: ["#47b784"],
          borderWidth: 2,
        },
        {
          label: "Cuentas eliminadas",
          data: [0, 0, 0, 2, 1, 0, 4, 3, 10, 2, 3, 8],
          backgroundColor: ["rgba(250, 32, 50, .5)"],
          borderColor: ["#fa4a51"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      lineTension: 1,
      scales: { yAxes: [{ ticks: { beginAtZero: true, padding: 25 } }] },
    },
  };
  public experience = {
    type: "doughnut",
    data: {
      labels: ["P_1", "P_2", "P_3", "P_4", "P_5"],
      datasets: [
        {
          label: "Calificación del usuario",
          data: [3, 15, 2, 8, 6],
          backgroundColor: [
            "rgba(250, 32, 50, .5)",
            "rgba(255, 87, 51, .5)",
            "rgba(255, 200, 42, .5)",
            "rgba(25, 155, 203, .5)",
            "rgba(55, 236, 113, .5)",
          ],
          borderColor: ["#fa4a51", "#f5846c", "#dfc72d", "#5cc7c7", "#6cf56e"],
          borderWidth: 2,
        },
      ],
    },
    options: { responsive: true, lineTension: 1 },
  };

  created(): void {
    let url = "";
    this.user = store.state.user;
    let companyId = "";
    if (store.state.user) {
      companyId =
        store.state.user.user.company._id != undefined
          ? store.state.user.user.company._id
          : store.state.user.user.company;
      url = `${BASE_URL_MANAGER}/user/all/${companyId}`;
    }
    Axios.get(url).then((res) => (this.users = res.data.users));
    Axios(`${BASE_URL_MANAGER}/agent/users/relAgents/${companyId}`).then(
      (res) =>
        (this.agentsLength = res.data.agents ? res.data.agents.length : 0)
    );
  }

  mounted(): void {
    //TODO: COMENTATO PORQUE NO EXISTE EL ID
    // this.createChart("account-data", this.accountData);
    // this.createChart("userExp", this.experience);
  }

  createChart(chartId: string, chartData: any): void {
    const ctx: any = document.getElementById(chartId);
    new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }
}
